.main-spinner-wrapper {
  display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* height: 100vh; */
    /* background: #eee; */
    position: absolute;
    left: 50%;
    top: 20%;

  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(#979fd0, 0.3);
    border-top-color: #979fd0;
    animation: 1.5s spin infinite linear;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}